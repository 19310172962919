<template>
  <div>
    <div>
        <p class="text-left text-xs" ><span class="icon-alert"><img alt="info" src="@/assets/images/info-26.png" title="Alert" /></span> {{info}}</p>
    </div>
  </div>
</template>

<script>
export default {
props: {
    info: String
    }
}
</script>

<style lang="scss" scoped>
    img {
        width: 15px;
        height: 15px;
        display: inline-block;
    }
</style>