<template>
  <div class="exclude-navbar face-compare-page">
    <container-section>
      <h1
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="text-center"
      >Face Compare</h1>
      <p
        data-aos="fade-up"
        data-aos-delay="350"
        data-aos-once="true"
        class="pt-3 md:w-4/5 m-auto text-center"
      >Identify same person present in different images. Determine the similarity between two faces. Search for a face in an image or video. We will use one detected face from target image and compare with all the faces present in the source image. This is done to avoid abuse, system has the capability to compare n number of faces. Tap on Submit to try now!</p>
      <face-compare-test-section
        v-on:on-passcode-valid="compareFaces($event)"
        :result="responseData"
        :isLoading="isLoading"
        :requestMade="userRequest"
        :sourceCoordinareBox="sourceCoordinareBox"
        :targetCoordinareBox="targetCoordinareBox"
      />

      <!-- try new models section -->
      <div data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
        <common-carousel />
      </div>
      <!-- end of try new models section -->

      <!-- Cards section -->
      <div class="row d-none">
        <div class="flex col-6 col-md-4 col-lg-3" v-for="card in applicationCards" :key="card.id">
          <details-card :title="card.title" :description="card.description" :to="card.route" />
        </div>
      </div>
    </container-section>

    <container-section :containerClass="'container-fluid'" :backgroundColor="'#fff'">
      <!-- FAQ's Section -->
      <div class="container mt-10 py-24">
        <h2
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-once="true"
          class="my-5"
        >Frequently asked questions</h2>
        <question-drop-down
          v-for="(question,index) of questionsData"
          :key="index"
          :title="question.question"
          :answer="question.answer"
        />
      </div>
    </container-section>

    <div class="about-footer relative">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import FaceCompareTestSection from "../components/AIComponents/FaceCompare/FaceCompareTestSection.vue";
import CommonCarousel from "../components/CommonCarousel.vue";
import ContainerSection from "../components/ContainerSection.vue";
import Footer from "../components/Home/Footer.vue";
import DetailsCard from "../components/Products/DetailsCard.vue";
import QuestionDropDown from "../components/QuestionComponens/QuestionDropDown.vue";
import DataServies from "../services/DataService";
import MessageService from "../services/MessageService";
import SessionService from "../services/SessionService";
import UrlPathService from "../services/UrlPathService";

export default {
  title: "Magure - Face Compare",

  components: {
    FaceCompareTestSection,
    ContainerSection,
    Footer,
    DetailsCard,
    QuestionDropDown,
    CommonCarousel
  },
  data() {
    return {
      applicationCards: DataServies.applicationCardsData,
      isLoading: false,
      responseTime: null,
      responseData: null,
      userRequest: null,
      sourceCoordinareBox: [],
      targetCoordinareBox: [],
      questionsData: DataServies.AIQuestion
    };
  },

  methods: {
    compareFaces(event) {
      this.userRequest = event;
      this.isLoading = true;
      UrlPathService.faceCompare(event)
        .then(response => {
          const response_data = response.data;
          const isValid = response_data.response_data;
          if (response_data.response_code === 0) {
            MessageService.show(response_data.description);
            this.isLoading = false;
          }
          if (isValid.is_vaild === false) {
            SessionService.clearEmailId();
            SessionService.clearPasscode();
            this.sourceCoordinareBox.length = 0;
            this.targetCoordinareBox.length = 0;
            this.isLoading = false;
          }
          if (response_data.response_code === 1) {
            let data = response_data;
            this.responseData = data.response_data;
            this.responseTime = data.time_taken;

            this.sourceCoordinareBox = [];
            this.targetCoordinareBox = [];

            var faceValues = this.responseData.face_matches;
            var targetValues = this.responseData.target_face;
            if (targetValues.box) {
              this.targetCoordinareBox.push(targetValues.box);
            }
            for (let item of faceValues) {
              if (item.box) {
                this.sourceCoordinareBox.push(item);
              }
            }
          }
          this.isLoading = false;
        })
        .catch(err => {
          let errorMsg = err.message
            ? err.message
            : "Some thing went wrong, Please Try again";
          MessageService.show(errorMsg);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style>
</style>