<template>
  <div class="absolute" style="left:-20px">
        <a class="info-popover blink" :id="'info'+ Math.floor(id)" tabindex="0" data-toggle="popover" data-container="body"  :data-content="'Face match : ' + value + '<br /> Similarity :'+ (similarity*100).toFixed(2) + '%'" data-html="true" data-placement="top" data-trigger="hover" @mouseover="emitHoverEvent(id)">
            <svg height="20" width="20" class="blinking">
                <circle cx="10" cy="10" r="10" fill="red" />
                Sorry, your browser does not support inline SVG.  
            </svg> 
        </a>
    </div>
</template>

<script>
export default {
    methods: {
        emitHoverEvent(id) {
            this.$emit('hovered',id)
        }
    },

    props: {
        id: Number,
        value: String,
        similarity: Number
    }
}
</script>

<style lang="scss" scoped>

</style>