<template>
  <div>
    <div class="section-title text-center">{{modelinput.title}}</div>
    <div class="input-image-section m-auto relative border-2" @drop="onChange">
      <img
        data-aos="zoom-in"
        data-aos-delay="300"
        data-aos-once="true"
        class="article-item__image"
        :src="inputURL ? inputURL : imgURL "
      />
      <div v-if="boxes && !isChanged">
        <div
          v-for="(item,index) of boxes"
          :key="index"
          class="absolute z-2 border-4 border-green-500"
          :style="{ left: item[0] + '%', top: item[1] + '%', width: item[2] + '%', height: item[3] + '%'}"
          :class="boxes? 'd-block' : ''"
        ></div>
      </div>
    </div>
    <div class="sample-img-section mt-5">
      <div class="pr-3">
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-once="true"
          class="text-small py-2"
        >Choose a Sample image</div>

        <div class="flex flex-col sm:flex-row">
          <div class="flex pr-3">
            <div
              class="imageone"
              :class="{ 'border-2 border-black p-1': imageOneSelected }"
              @click="[imageTwoSelected=false,imageOneSelected=true]"
            >
              <img
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-once="true"
                :src="modelinput.sampleImageOne"
                alt
                @click="GetSource($event)"
                class="object-cover"
              />
            </div>
            <div
              class="imagetwo ml-3"
              :class="{ 'border-2 border-black p-1': imageTwoSelected }"
              @click="[imageTwoSelected=true,imageOneSelected=false]"
            >
              <img
                data-aos="zoom-in"
                data-aos-delay="300"
                data-aos-once="true"
                :src="modelinput.sampleImageTwo"
                alt
                @click="GetSource($event)"
                class="object-cover"
              />
            </div>
          </div>
          <!--  -->
          <div class="text-small relative pl-3 sm:border-l d-none" v-if="modelinput.isLocalInput">
            <div class="text-sm">
              <p>
                Use your own image
                <br />
                <span class="text-small">image must be .jpeg or png formant</span>
              </p>
            </div>

            <label v-if="!uploadImg" class="mb-0 btn btn-dark display-inline">
              SELECT OR DROP AN IMAGE in Image Area
              <input
                type="file"
                name="image"
                @change="onChange"
              />
            </label>
          </div>
        </div>

        <!-- input section -->
        <div class="inputURLSection sm:pl-3 w-100 border-t mt-2 relative">
          <form class="mt-3">
            <label data-aos="fade-up" data-aos-delay="300" data-aos-once="true" for="urlInput">URL</label>
            <div class="input-group mb-3 text-small">
              <input
                data-aos="fade-up"
                data-aos-delay="350"
                data-aos-once="true"
                ref="fileInput"
                v-model="inputURL"
                type="text"
                class="form-control"
                placeholder="Please input img URL"
                aria-label="imageurl"
                @change.prevent="onUrlSubmit()"
              />
            </div>
          </form>
        </div>
        <!-- end of input url -->
      </div>
    </div>
  </div>
</template>

<script>
// import EmailInputModal from '../Products/EmailInputModal.vue';
// import PasscodeInputModal from './PasscodeInputModal.vue';

export default {
  components: {
    // EmailInputModal,
    // PasscodeInputModal,
  },
  data() {
    return {
      imgURL: this.modelinput.imgURL,
      inputURL: "",
      uploadImg: "",
      files: "",
      isResult: false,
      bucketName: "magure-inc-demo-images",
      emailAddress: null,
      isEmailEmpty: Boolean,
      isPasscodeEmpty: Boolean,
      isChanged: false,
      imageOneSelected: true,
      imageTwoSelected: false
    };
  },
  methods: {
    onEmailSubmit(event) {
      this.emailAddress = event.email;
      this.showEmailInputModal = false;
      this.$emit("on-email-submit", this.emailAddress);
      this.showPasscodeModal = true;
    },

    onPassCodeSubmit() {
      this.showPasscodeModal = false;
      this.$emit("on-passcode-submit", this.imgURL);
    },

    onUrlSubmit() {
      this.imgURL = this.inputURL ? this.inputURL : this.imgURL;
      this.$emit("selected-url", this.imgURL);
    },

    GetSource(e) {
      this.inputURL = "";
      this.imgURL = e.target.currentSrc;
      this.$emit("selected-url", this.imgURL);
    },

    onChange(e) {
      e.stopPropagation();
      e.preventDefault();
      this.files = e.target.files ? e.target.files : e.dataTransfer.files;
      // this.createFile(this.files[0]);
      this.inputURL = "";
      this.imgURL = this.files[0];
    },

    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      var reader = new FileReader();
      var instace = this;

      reader.onload = function(e) {
        instace.imgURL = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  },

  watch: {
    isLoading: function(newVal) {
      if (newVal === false) {
        this.isChanged = false;
      }
    },

    inputURL(newVal) {
      if (newVal) {
        // this.isChanged = true;
        this.imageOneSelected = false;
        this.imageTwoSelected = false;
      } else {
        this.imageOneSelected = true;
        this.imgURL = this.modelinput.sampleImageOne;
      }
    },

    imgURL(newVal) {
      if (newVal) {
        this.isChanged = true;
      }
    }
  },

  props: {
    modelinput: Object,
    boxes: Array,
    isLoading: Boolean
  }
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: 1.2em;
}
::placeholder {
  font-size: 12px;
}
input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
img {
  width: 100%;
  height: 100%;
  // object-fit: cover;
}
.input-image-section {
  height: auto;
  width: auto;
  // max-height: 53vw;
  @media (max-width: 500px) {
    height: 15em;
    width: 50%;
  }
}
.sample-img-section {
  .imageone,
  .imagetwo {
    height: 6em;
    width: 6em;
    cursor: pointer;
  }
}

@media (max-width: 500px) {
  .inputURLSection {
    margin-top: 1em;
  }
}
</style>