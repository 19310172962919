<template>
  <div class="my-16">
    <div class="row relative">
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="col-sm-6 col-md-6 col-lg-6"
      >
        <face-compare-image-target
          :modelinput="targetImageInput"
          v-on:selected-url="targetImg($event)"
          :boxes="targetCoordinareBox"
          :isLoading="isLoading"
        />
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="col-sm-6 col-md-6 col-lg-6"
      >
        <face-compare-image-source
          :modelinput="sourceImageInput"
          v-on:selected-url="sourceImg($event)"
          :boxes="sourceCoordinareBox"
          :isLoading="isLoading"
        />
      </div>
      <email-input-modal
        class="absolute"
        v-bind:is-visible.sync="showEmailInputModal"
        v-on:valid-email="onEmailSubmit($event)"
        v-on:close="showEmailInputModal=false"
      />

      <passcode-input-modal
        class="absolute"
        v-bind:is-visible.sync="showPasscodeModal"
        v-on:valid-passcode="onPassCodeSubmit()"
        :email="emailAddress"
        v-on:close="showPasscodeModal=false"
        v-on:invalid-passcode="showPasscodeModal=false"
      />
    </div>

    <div>
      <base-button
        :isConfirm="true"
        class="btn btn-primary mx-auto"
        :state="submitButtonState"
        @click="SubmitImages()"
      >Submit</base-button>
    </div>

    <div
      data-aos="fade-up"
      data-aos-delay="300"
      data-aos-once="true"
      class="my-5 text-center"
      v-if="result"
    >
      RESULT
      <info-component :info="'Red depicts face detected. Green shows same person detected.'" />
      <face-compare-result v-bind:receivedResult="requestMade" :title="'Request'" />
      <face-compare-result v-bind:receivedResult="result" :title="'Response JSON'" />
    </div>
  </div>
</template>

<script>
import ButtonState from "../../../lib/button-state";
import DataServies from "../../../services/DataService";
import SessionService from "../../../services/SessionService";
// import ResultDropDownSection from '../../AIComponents/ResultDropDownSection.vue';
import PasscodeInputModal from "../../AIComponents/PasscodeInputModal.vue";
import BaseButton from "../../BaseButton.vue";
import InfoComponent from "../../InfoComponent.vue";
import EmailInputModal from "../../Products/EmailInputModal.vue";

import FaceCompareImageSource from "./FaceCompareImageSource.vue";
import FaceCompareImageTarget from "./FaceCompareImageTarget.vue";
import FaceCompareResult from "./FaceCompareResult.vue";

export default {
  components: {
    // FaceCompareImage,
    FaceCompareImageSource,
    FaceCompareImageTarget,
    BaseButton,
    EmailInputModal,
    PasscodeInputModal,
    // ResultDropDownSection,
    FaceCompareResult,
    InfoComponent
  },
  data() {
    return {
      selectedSourceURL: "",
      selectedTargetURL: "",
      submitButtonState: ButtonState.Ready,
      showEmailInputModal: false,
      showPasscodeModal: false,
      emailAddress: "",
      isEmailGiven: Boolean,
      isPasscodeGiven: Boolean,
      sourceImageInput: DataServies.modelStaticData.FCsourceImageInput,
      targetImageInput: DataServies.modelStaticData.FCtargetImageInput,
      responseTime: null,
      responseData: null
    };
  },

  methods: {
    sourceImg(e) {
      this.selectedSourceURL = e;
    },

    targetImg(e) {
      this.selectedTargetURL = e;
    },

    onEmailSubmit(event) {
      this.emailAddress = event.email;
      this.showEmailInputModal = false;
      this.$emit("on-email-submit", this.emailAddress);
      this.showPasscodeModal = true;
    },
    onPassCodeSubmit() {
      this.showPasscodeModal = false;

      let data = {
        target_image_url:
          this.selectedTargetURL != ""
            ? this.selectedTargetURL
            : this.targetImageInput.imgURL,
        source_image_url:
          this.selectedSourceURL != ""
            ? this.selectedSourceURL
            : this.sourceImageInput.imgURL,
        email_id: SessionService.getClientEmaiId(),
        passcode: SessionService.getPasscode()
      };
      this.$emit("on-passcode-valid", data);
    },

    SubmitImages() {
      this.isEmailGiven = SessionService.getIsCurrentClientGivenEmail();
      this.isPasscodeGiven = SessionService.getIsPasscodePresent();
      if (this.isEmailGiven && this.isPasscodeGiven) {
        this.submitButtonState = ButtonState.Loading;
        this.onPassCodeSubmit();
      } else {
        this.showEmailInputModal = true;
      }
    }
  },
  watch: {
    isLoading: function(newVal) {
      if (newVal === false) {
        this.submitButtonState = ButtonState.Ready;
      }
    }
  },

  props: {
    result: Object,
    requestMade: Object,
    isLoading: Boolean,
    sourceCoordinareBox: Array,
    targetCoordinareBox: Array
  }
};
</script>

<style>
</style>