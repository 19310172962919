<template>
  <div>
    <div class="result-heading flex p-2 justify-between" v-on:click="toggle()">
      <h4
        data-aos="fade-up"
        data-aos-delay="300"
        data-aos-once="true"
        class="result-name py-2"
      >{{title}}</h4>
      <button class="btn btn-transparent">
        <caret-down-icon v-if="!isCollapsed" v-bind:alt="'Open '" v-bind:title="'Open '"></caret-down-icon>
        <caret-up-icon v-else v-bind:alt="'Close '" v-bind:title="'Close '"></caret-up-icon>
      </button>
    </div>
    <div
      data-aos="fade-up"
      data-aos-delay="300"
      data-aos-once="true"
      class="text-left relative p-2"
      :class="[title =='Request' ? 'h-46': 'h-96',receivedResult? 'overflow-auto' : '',] "
      v-if="isCollapsed"
    >
      <pre>{{ JSON.stringify(receivedResult, null, '\t') }}</pre>
    </div>
  </div>
</template>

<script>
import CaretDownIcon from "../../../icons/CaretDownIcon.vue";
import CaretUpIcon from "../../../icons/CaretUpIcon.vue";
export default {
  components: {
    CaretDownIcon,
    CaretUpIcon
  },
  data() {
    return {
      isCollapsed: Boolean
    };
  },
  methods: {
    toggle() {
      this.isCollapsed = !this.isCollapsed;
    }
  },
  watch: {
    receivedResult: function(newval) {
      if (newval !== null) {
        this.isCollapsed = true;
      }
    }
  },

  props: {
    title: String,
    receivedResult: Object
  }
};
</script>

<style lang="scss" scoped>
.result-heading {
  background: whitesmoke;
}
</style>